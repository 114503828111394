import Layout from 'components/Layout';
import { above } from 'components/config/mediaqueries';
import styled from 'libs/styled';
import React from 'react';

const Div = styled('div')``;

const PageWrapper = styled('div')`
    padding-bottom: 120px;
    background: var(--color-secondaryBackground);
    transition: background 0.1s ease-in-out;
`;

const TextHeading = styled('h2')`
    font-family: 'Roboto';
    font-size: 42px;
    margin-bottom: 16px;
`;

const HeroSection = styled('section')`
    line-height: 1.5;
    grid-column: 2 / span 6;
    font-family: 'Roboto';
    padding: 0 24px;

    background: var(--color-background);
    transition: background 0.1s ease-in-out;

    ${above.tabletSm} {
        grid-column: 2 / span 10;
        grid-row: unset;
    }
    ${above.desktopLg} {
        grid-column: 3 / span 8;
    }

    p {
        margin: 0;
        margin-bottom: 12px;
        font-size: 14px;

        ${above.tabletSm} {
            font-size: 16px;
        }
        ${above.desktopLg} {
            font-size: 18px;
        }
    }

    h1 {
        font-family: 'Roboto';
        font-size: 18px;
        margin-bottom: 32px;

        ${above.tabletSm} {
            font-size: 24px;
        }
        ${above.desktopLg} {
            font-size: 32px;
        }
    }
`;

const TextWrapper = styled('div')`
    height: 100%;
`;

const HeroGrid = styled('div')`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 24px 0;
  gap="24px";

   ${above.tabletSm} {
    display: grid;
    gap: 16px;
    grid-row-gap: normal;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
  }
`;

const IndexPage = () => (
    <Layout>
        <PageWrapper>
            <HeroSection>
                <Div display="flex" flexDirection="column" height="100%" paddingTop={['48px', '56px', '72px']}>
                    <HeroGrid>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Privacy Policy for https://www.lorema.app
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                At the lorema app's website, accessible from https://www.lorema.app, one of our main
                                priorities is the privacy of our visitors. This Privacy Policy document contains types
                                of information that is collected and recorded by the lorema app's website and how we use
                                it. If you have additional questions or require more information about our Privacy
                                Policy, do not hesitate to contact us. This Privacy Policy applies only to our online
                                activities and is valid for visitors to our website with regards to the information that
                                they shared and/or collect in the lorema app's website. This policy is not applicable to
                                any information collected offline or via channels other than this website. Our Privacy
                                Policy was created with the help of the Free Privacy Policy Generator.
                            </p>
                        </TextWrapper>

                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>Consent</TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Information we collect
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                The personal information that you are asked to provide, and the reasons why you are
                                asked to provide it, will be made clear to you at the point we ask you to provide your
                                personal information. If you contact us directly, we may receive additional information
                                about you such as your name, email address, phone number, the contents of the message
                                and/or attachments you may send us, and any other information you may choose to provide.
                                When you register for an Account, we may ask for your contact information, including
                                items such as name, company name, address, email address, and telephone number.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            How we use your information
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>
                                    Communicate with you, either directly or through one of our partners, including for
                                    customer service, to provide you with updates and other information relating to the
                                    website, and for marketing and promotional purposes
                                </li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>Log files</TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                the lorema app's website follows a standard procedure of using log files. These files
                                log visitors when they visit websites. All hosting companies do this and a part of
                                hosting services' analytics. The information collected by log files include internet
                                protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time
                                stamp, referring/exit pages, and possibly the number of clicks. These are not linked to
                                any information that is personally identifiable. The purpose of the information is for
                                analyzing trends, administering the site, tracking users' movement on the website, and
                                gathering demographic information.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Cookies and Web Beacons
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                Like any other website, the lorema app's website uses 'cookies'. These cookies are used
                                to store information including visitors' preferences, and the pages on the website that
                                the visitor accessed or visited. The information is used to optimize the users'
                                experience by customizing our web page content based on visitors' browser type and/or
                                other information.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Our Advertising Partners
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                Some of advertisers on our site may use cookies and web beacons. Our advertising
                                partners are listed below. Each of our advertising partners has their own Privacy Policy
                                for their policies on user data. For easier access, we hyperlinked to their Privacy
                                Policies below.
                            </p>
                            <ul>
                                <li>
                                    <p>Google</p>
                                    <p>
                                        <a href="https://policies.google.com/technologies/ads">
                                            https://policies.google.com/technologies/ads
                                        </a>
                                    </p>
                                </li>
                            </ul>
                        </TextWrapper>

                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Advertising Partners Privacy Policies
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                You may consult this list to find the Privacy Policy for each of the advertising
                                partners of the lorema app's website. Third-party ad servers or ad networks uses
                                technologies like cookies, JavaScript, or Web Beacons that are used in their respective
                                advertisements and links that appear on the lorema app's website, which are sent
                                directly to users' browser. They automatically receive your IP address when this occurs.
                                These technologies are used to measure the effectiveness of their advertising campaigns
                                and/or to personalize the advertising content that you see on websites that you visit.
                                Note that the lorema app's website has no access to or control over these cookies that
                                are used by third-party advertisers.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Third Party Privacy Policies
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                the lorema app's website's Privacy Policy does not apply to other advertisers or
                                websites. Thus, we are advising you to consult the respective Privacy Policies of these
                                third-party ad servers for more detailed information. It may include their practices and
                                instructions about how to opt-out of certain options. You can choose to disable cookies
                                through your individual browser options. To know more detailed information about cookie
                                management with specific web browsers, it can be found at the browsers' respective
                                websites.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            CCPA Privacy Rights (Do Not Sell My Personal Information)
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>Under the CCPA, among other rights, California consumers have the right to: </p>
                            <p>
                                Request that a business that collects a consumer's personal data disclose the categories
                                and specific pieces of personal data that a business has collected about consumers.
                            </p>{' '}
                            <p>
                                Request that a business delete any personal data about the consumer that a business has
                                collected.
                            </p>{' '}
                            <p>
                                Request that a business that sells a consumer's personal data, not sell the consumer's
                                personal data.
                            </p>{' '}
                            <p>
                                If you make a request, we have one month to respond to you. If you would like to
                                exercise any of these rights, please contact us.
                            </p>{' '}
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            GDPR Data Protection Rights
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                We would like to make sure you are fully aware of all of your data protection rights.
                                Every user is entitled to the following:
                            </p>
                            <p>
                                The right to access – You have the right to request copies of your personal data. We may
                                charge you a small fee for this service.
                            </p>
                            <p>
                                The right to rectification – You have the right to request that we correct any
                                information you believe is inaccurate. You also have the right to request that we
                                complete the information you believe is incomplete.
                            </p>
                            <p>
                                The right to erasure – You have the right to request that we erase your personal data,
                                under certain conditions.
                            </p>
                            <p>
                                The right to restrict processing – You have the right to request that we restrict the
                                processing of your personal data, under certain conditions.
                            </p>
                            <p>
                                The right to object to processing – You have the right to object to our processing of
                                your personal data, under certain conditions.
                            </p>
                            <p>
                                The right to data portability – You have the right to request that we transfer the data
                                that we have collected to another organization, or directly to you, under certain
                                conditions.
                            </p>
                            <p>
                                If you make a request, we have one month to respond to you. If you would like to
                                exercise any of these rights, please contact us.
                            </p>
                        </TextWrapper>
                        <TextHeading gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}>
                            Children's Information
                        </TextHeading>
                        <TextWrapper
                            fontSize={['16px', null, '18px']}
                            gridColumn={['1 / span 12', '2 / span 10', '2 / span 10']}
                            marginBottom="40px"
                        >
                            <p>
                                Another part of our priority is adding protection for children while using the internet.
                                We encourage parents and guardians to observe, participate in, and/or monitor and guide
                                their online activity.
                            </p>
                            <p>
                                the lorema app's website does not knowingly collect any Personal Identifiable
                                Information from children under the age of 13. If you think that your child provided
                                this kind of information on our website, we strongly encourage you to contact us
                                immediately and we will do our best efforts to promptly remove such information from our
                                records.
                            </p>
                        </TextWrapper>
                    </HeroGrid>
                </Div>
            </HeroSection>
        </PageWrapper>
    </Layout>
);

export default IndexPage;
